import { axiosInstance } from "../../utils/axiosInstance";

export const RESULTS_REQUEST = "RESULTS_REQUEST";
export const RESULTS_SUCCESS = "RESULTS_SUCCESS";
export const RESULTS_FAILURE = "RESULTS_FAILURE";

export const GetAllResults = (query) => {
  return async (dispatch) => {
    dispatch({ type: RESULTS_REQUEST });
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axiosInstance.get("/admin/api/v1/results", {
        params: query,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === 201) {
        dispatch({ type: RESULTS_SUCCESS, payload: response?.data?.data });
      } else {
        dispatch({ type: RESULTS_FAILURE, payload: response?.data?.message });
      }
    } catch (error) {
      dispatch({ type: RESULTS_FAILURE, payload: error?.message });
    }
  };
};
