import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import Navbar from "../Components/Navbar/Navbar";
import Sidebar from "../Components/Navbar/Sidebar";
import Login from "../Pages/auth/Login";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Add from "../Pages/Jobs/Add";
import Job from "../Pages/Jobs/Job";
import ProtectedRoute from "./ProtectedRoute";
import Faq from "../Pages/FAQ/Faq";
import AddFaqModal from "../Pages/FAQ/AddFaqModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactUs from "../Pages/contactUs/ContactUs";
import EditJob from "../Pages/Jobs/Edit";
import Admitcard from "../Pages/admitcard/Admitcard";
import Results from "../Pages/result/Results";
import Register from "../Pages/auth/Register";

const Routing = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const navigation = useNavigate();

  // console.log(isAuthenticated, user);

  const [isAuth, setIsAuth] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  useEffect(() => {
    localStorage.getItem("accessToken") ? setIsAuth(true) : setIsAuth(false);

    if (!isAuthenticated && !localStorage.getItem("accessToken")) {
      navigation("/login");
    }
  }, [navigation, isAuth, isAuthenticated]);

  return (
    <>
      {isAuth ? (
        <Box sx={{ display: "flex", minHeight: "100vh" }}>
          <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarClose} />
          <Box component="main" sx={{ flexGrow: 2, p: 3 }}>
            <Navbar onOpen={handleSidebarToggle} />
            <Box>
              <Routes>
                <Route element={<ProtectedRoute isAuthenticated={isAuth} />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/add-faq" element={<AddFaqModal />} />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/jobs" element={<Job />} />
                  <Route path="/jobs/edit/:id" element={<EditJob />} />
                  <Route path="/jobs/new-post" element={<Add />} />
                  <Route path="/job-form" element={<Add />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/admitcard" element={<Admitcard />} />
                  <Route path="/results" element={<Results />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="*" element={() => <h1>404 Page Not Found</h1>} />
                </Route>
              </Routes>
            </Box>
          </Box>
        </Box>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      )}
      <ToastContainer />
    </>
  );
};

export default Routing;
