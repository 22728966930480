import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Loader/Loading";
import {
  contactUsDeleteData,
  contactUsGetData,
  updateMessage,
} from "../../Redux/contactus/contact.action";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { data, isLoading, isError, isDeleteLoading, deleteError } =
    useSelector((state) => state.contactusReducer);

  const [open, setOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reply, setReply] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    dispatch(contactUsGetData({ page: page, limit: rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  const handleOpen = (message) => {
    setSelectedMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMessage(null);
    setReply("");
  };

  const handleSendReply = () => {
    dispatch(
      updateMessage(selectedMessage._id, {
        replyMessage: reply,
        isReplied: true,
      })
    ).then((res) => {
      if (res === "SUCCESS") {
        handleClose();
      }
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Typography color="error">Error fetching messages</Typography>;
  }

  const handleDeleteClick = (id) => {
    dispatch(contactUsDeleteData(id));
  };

  const QuickFilter = (filter) => {
    const query = { limit: 100 };
    if (filter === "deleted") query.isDeleted = "true";
    if (filter === "replied") query.isReplied = "true";
    if (filter === "read") query.isRead = "true";
    if (filter === "resolved") query.isResolved = "true";
    if (filter === "all") query.limit = 10;

    dispatch(contactUsGetData(query));
  };

  return (
    <Box sx={{ mt: 8 }}>
      <Box
        display="flex"
        flexWrap={"wrap"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom>
          Messages
        </Typography>
        <Box sx={{ padding: 1 }}>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            color="primary"
            onClick={() => QuickFilter("all")}
          >
            All
          </Button>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            color="primary"
            onClick={() => QuickFilter("deleted")}
          >
            Deleted
          </Button>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            color="primary"
            onClick={() => QuickFilter("replied")}
          >
            Replied
          </Button>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            color="primary"
            onClick={() => QuickFilter("read")}
          >
            Read
          </Button>
          <Button
            variant="contained"
            sx={{ m: 1 }}
            color="primary"
            onClick={() => QuickFilter("resolved")}
          >
            Resolved
          </Button>
        </Box>
      </Box>
      {data?.data?.map((message) => (
        <Box
          key={message._id}
          mb={3}
          p={2}
          borderRadius={4}
          boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
        >
          <Typography variant="h6">Type: {message.message_type}</Typography>
          <Typography variant="body1">Name: {message.name}</Typography>
          <Typography variant="body1">Email: {message.email}</Typography>
          <Typography variant="body1">Message: {message.message}</Typography>
          <Box
            display="flex"
            flexWrap={"wrap"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Button
                sx={{ mt: 2, mr: 1 }}
                onClick={() => handleOpen(message)}
                disabled={message?.isResolved}
              >
                Reply
              </Button>
              <Button
                sx={{ mt: 2, color: "red" }}
                onClick={() => handleDeleteClick(message._id)}
                disabled={message?.isDeleted}
              >
                Delete
              </Button>
            </Box>
            <Box>
              <Button sx={{ mt: 2, mr: 1, color: message.isRead ? "red" : "" }}>
                {message.isRead ? "Read" : "Unread"}
              </Button>
              <Button sx={{ mt: 2, color: message.isDeleted ? "red" : "" }}>
                {message.isDeleted ? "Deleted" : "Not-Deleted"}
              </Button>
              <Button sx={{ mt: 2, color: message.isResolved ? "red" : "" }}>
                {message.isResolved ? "Resolved" : "UnResolved"}
              </Button>
              <Button sx={{ mt: 2, color: message.isReplied ? "red" : "" }}>
                {message.isReplied ? "Replied" : "Not-Replied"}
              </Button>
            </Box>
          </Box>
        </Box>
      ))}

      <Box
        flexWrap="wrap"
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        gap={{ lg: "2rem", sm: "1rem", xs: ".5rem" }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: { lg: "1.1rem", sm: "1.3rem", xs: ".9rem" },
          }}
        >
          Row Per Page
        </Typography>

        <Select
          value={rowsPerPage}
          onChange={(e) => setRowsPerPage(e.target.value)}
          sx={{
            height: "2rem",
            fontSize: { lg: "1.1rem", sm: "1.3rem", xs: ".9rem" },
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
        <Pagination
          count={Math.ceil(data?.total / rowsPerPage)}
          page={page}
          onChange={(e, p) => setPage(p)}
          variant="outlined"
          shape="rounded"
        />
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Reply to: {selectedMessage?.name}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Reply"
            type="text"
            fullWidth
            value={reply}
            onChange={(e) => setReply(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSendReply} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {isDeleteLoading && <Loading />}
      {deleteError && <Alert severity="error">Error deleting message.</Alert>}
    </Box>
  );
};

export default ContactUs;
