import {
  CONTACTUS_DELETE_FAILURE,
  CONTACTUS_DELETE_REQUEST,
  CONTACTUS_DELETE_SUCCESS,
  CONTACTUS_FAILURE,
  CONTACTUS_REQUEST,
  CONTACTUS_SUCCESS,
} from "./contact.action";

const initialContact = {
  isLoading: false,
  isError: null,
  data: { data: [], total: 0, nextUrl: null },
};

export const reducer = (state = initialContact, { type, payload }) => {
  switch (type) {
    case CONTACTUS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case CONTACTUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: null,
        data: payload,
      };
    case CONTACTUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: payload,
      };
    case CONTACTUS_DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
      };
    case CONTACTUS_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: null,
        data: state.data.data.filter((item) => item._id !== payload),
      };
    case CONTACTUS_DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: payload,
      };
    default:
      return state;
  }
};
