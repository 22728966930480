import {
  FAQ_ERROR,
  FAQ_REQUEST,
  GET_FAQ_SUCCESS,
  POST_FAQ_SUCCESS,
} from "./faq.action";

const initialFaq = {
  isLoading: false,
  isError: null,
  data: { data: [], total: 0, nextUrl: null },
};

export const reducer = (state = initialFaq, { type, payload }) => {
  switch (type) {
    case FAQ_REQUEST:
      return { ...state, isLoading: true, isError: null };
    case GET_FAQ_SUCCESS:
      return { ...state, isLoading: false, isError: null, data: payload };
    case POST_FAQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: null,
        data: [...state.data, payload],
      };
    case FAQ_ERROR:
      return { ...state, isLoading: false, isError: payload };
    default:
      return state;
  }
};
