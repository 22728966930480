import { axiosInstance } from "../../utils/axiosInstance";
import { toast } from "react-toastify";

export const FAQ_REQUEST = "FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const FAQ_ERROR = "FAQ_ERROR";
export const POST_FAQ_SUCCESS = "POST_FAQ_SUCCESS";

// const token = localStorage.getItem("accessToken");

const faqRequest = () => ({
  type: FAQ_REQUEST,
});

const faqSuccess = (data) => ({
  type: GET_FAQ_SUCCESS,
  payload: data,
});
const faqError = (error) => ({
  type: FAQ_ERROR,
  payload: error,
});

export const fetchFaqData = (query) => {
  return async (dispatch) => {
    const token = localStorage.getItem("accessToken");
    dispatch(faqRequest());
    try {
      const response = await axiosInstance.get("/admin/api/v1/faqs", {
        params: query,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(faqSuccess(response?.data?.data));
    } catch (error) {
      dispatch(faqError(error));
      toast.error("Failed to fetch FAQs");
    }
  };
};

export const addFaqData = ({ question, answer }) => {
  return async (dispatch) => {
    const token = localStorage.getItem("accessToken");
    dispatch(faqRequest());
    try {
      const response = await axiosInstance.post(
        "/api/v1/faqs",
        { question, answer },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response?.data?.status === 201) {
        dispatch(fetchFaqData({ limit: 10 }));
        toast.success("FAQ added successfully");
      }
    } catch (error) {
      console.error("Error:", error);
      dispatch(faqError(error));
      toast.error("Failed to add FAQ");
    }
  };
};

export const DeleteFaq = (id) => {
  return async (dispatch) => {
    dispatch(faqRequest());
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axiosInstance.delete(`/api/v1/faqs/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.status === 200) {
        dispatch(fetchFaqData({ limit: 10 }));
        toast.success("FAQ Deleted successfully");
        return "SUCCESS";
      } else {
        dispatch(faqError(response?.data.message));
      }
    } catch (error) {
      dispatch(faqError(error.message));
      toast.error("Failed to Delete FAQ");
    }
  };
};

export const updateFaq = (id, data) => {
  return async (dispatch) => {
    dispatch(faqRequest());
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axiosInstance.put(`/api/v1/faqs/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.status === 200) {
        dispatch(fetchFaqData({ limit: 10 }));
        toast.success("FAQ Updated successfully");
        return "SUCCESS";
      } else {
        dispatch(faqError(response?.data.message));
      }
    } catch (error) {
      dispatch(faqError(error.message));
      toast.error("Failed to Updated FAQ");
    }
  };
};
