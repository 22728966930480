import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { addFaqData } from "../../Redux/faq/faq.action";

const AddFaqModal = ({ open, handleClose }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addFaqData({ question, answer }));
    setQuestion("");
    setAnswer("");
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "75%", md: "50%", lg: "40%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <Typography variant="h4" gutterBottom>
            Add FAQ
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Question"
              fullWidth
              margin="normal"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
            <TextField
              label="Answer"
              fullWidth
              margin="normal"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              required
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{ mt: 2 }}
            >
              Add FAQ
            </Button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AddFaqModal;
