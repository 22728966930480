import {
  Box,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetAllAdmitCards } from "../../Redux/admitcard/admitcard.action";
import {
  GetAllJobs,
  GetQuickOrNewJobs,
  updateJob,
} from "../../Redux/jobs/job.action";
import { GetAllResults } from "../../Redux/result/result.action";

const categories = [
  { name: "All Jobs", value: 0, pageName: "jobs" },
  { name: "All AdmitCard", value: 0, pageName: "admitcard" },
  { name: "All Results", value: 0, pageName: "results" },
  { name: "Quick Jobs", value: 0, pageName: "" },
  { name: "New Jobs", value: 0, pageName: "" },
  { name: "Application Link Activate today", value: 0, pageName: "" },
  { name: "Expire today", value: 0, pageName: "" },
  { name: "Post today", value: 0, pageName: "" },
];

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    AllJobs,
    QuickJobs,
    NewJobs,
    PostedToday,
    ExpireToday,
    ActivateToday,
  } = useSelector((state) => state.JobReducer);
  const { AllResults } = useSelector((state) => state.Results);
  const { AllAdmitCard } = useSelector((state) => state.Admitcard);

  useEffect(() => {
    dispatch(GetAllJobs());
    dispatch(GetAllJobs({ last_date: "today" }));
    dispatch(GetAllJobs({ apply_link_avl_from: "today" }));
    dispatch(GetAllJobs({ date_range: "today" }));
    dispatch(GetQuickOrNewJobs("new"));
    dispatch(GetQuickOrNewJobs("quick"));
    dispatch(GetAllAdmitCards());
    dispatch(GetAllResults());
  }, [dispatch]);

  useEffect(() => {
    categories[0].value = AllJobs?.total;
    categories[1].value = AllAdmitCard?.total;
    categories[2].value = AllResults?.total;
    categories[3].value = QuickJobs?.total;
    categories[4].value = NewJobs?.total;
    categories[5].value = ActivateToday?.total;
    categories[6].value = ExpireToday?.total;
    categories[7].value = PostedToday?.total;
  }, [
    AllJobs,
    AllAdmitCard,
    AllResults,
    QuickJobs,
    NewJobs,
    ActivateToday,
    ExpireToday,
    PostedToday,
  ]);

  const handleLive = (id, e, type) => {
    if (type === "quick") {
      dispatch(updateJob(id, { is_quick_link: e.target.checked }));
    } else if (type === "new") {
      dispatch(updateJob(id, { is_new_post: e.target.checked }));
    } else if (type === "live") {
      dispatch(updateJob(id, { is_live: e.target.checked }));
    }
  };

  return (
    <Box mt={8}>
      <Typography variant="h4" mb={4}>
        Dashboard
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px 10px" }}>
        {categories.map((category, i) => (
          <Box
            onClick={() => navigate(`/${category.pageName}`)}
            key={i}
            sx={{
              bgcolor: getRandomColor(),
              borderRadius: "5px",
              padding: "15px",
              boxShadow: "md",
              flex: "1",
              minWidth: "200px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "white",
              cursor: "pointer",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {category.name}
            </Typography>
            <Typography variant="h5">{category.value}</Typography>
          </Box>
        ))}
      </Box>

      {/*  */}
      <Grid container spacing={2} mt={4}>
        {/* Application link activate today */}
        <Grid item xs={12} md={6} lg={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">
                      Application Link Activate Today
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "#1e3a8a" }}>
                  <TableCell style={{ color: "white" }}>Title</TableCell>
                  {/* <TableCell style={{ color: "white" }}>Edit</TableCell> */}
                  <TableCell style={{ color: "white" }}>Live</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ActivateToday?.posts?.length === 0 ? (
                  <TableRow>No data available</TableRow>
                ) : (
                  ActivateToday?.posts?.map((job) => (
                    <TableRow key={job?._id}>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/jobs/edit/${job?._id}`)}
                      >
                        {job?.vacancy_title}
                      </TableCell>
                      <TableCell>
                        <Switch
                          defaultChecked={job?.is_live}
                          defaultValue={job?.is_live}
                          color="success"
                          onChange={(e) => handleLive(job?._id, e, "live")}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Application Expire today */}
        <Grid item xs={12} md={6} lg={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">
                      Application expire today
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "#1e3a8a" }}>
                  <TableCell style={{ color: "white" }}>Title</TableCell>
                  {/* <TableCell style={{ color: "white" }}>Edit</TableCell> */}
                  <TableCell style={{ color: "white" }}>Live</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ExpireToday?.posts?.length === 0 ? (
                  <TableRow>No data available</TableRow>
                ) : (
                  ExpireToday?.posts?.map((job) => (
                    <TableRow key={job?._id}>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/jobs/edit/${job?._id}`)}
                      >
                        {job?.vacancy_title}
                      </TableCell>
                      <TableCell>
                        <Switch
                          defaultChecked={job?.is_live}
                          defaultValue={job?.is_live}
                          color="success"
                          onChange={(e) => handleLive(job?._id, e, "live")}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Application Posted today */}
        <Grid item xs={12} md={6} lg={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">Today's Posts</Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "#1e3a8a" }}>
                  <TableCell style={{ color: "white" }}>Title</TableCell>
                  {/* <TableCell style={{ color: "white" }}>Edit</TableCell> */}
                  <TableCell style={{ color: "white" }}>Live</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PostedToday?.posts?.length === 0 ? (
                  <TableRow>No data available</TableRow>
                ) : (
                  PostedToday?.posts?.map((job) => (
                    <TableRow key={job?._id}>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/jobs/edit/${job?._id}`)}
                      >
                        {job?.vacancy_title}
                      </TableCell>
                      <TableCell>
                        <Switch
                          defaultChecked={job?.is_live}
                          defaultValue={job?.is_live}
                          color="success"
                          onChange={(e) => handleLive(job?._id, e, "live")}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Quick Table */}
        <Grid item xs={12} md={6} lg={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">Quick Posts</Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "#1e3a8a" }}>
                  <TableCell style={{ color: "white" }}>Title</TableCell>
                  {/* <TableCell style={{ color: "white" }}>Edit</TableCell> */}
                  <TableCell style={{ color: "white" }}>
                    Remove From Quick
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {QuickJobs?.posts?.length === 0 ? (
                  <TableRow>No data available</TableRow>
                ) : (
                  QuickJobs?.posts?.map((job) => (
                    <TableRow
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/jobs/edit/${job?._id}`)}
                      key={job?._id}
                    >
                      <TableCell
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/jobs/edit/${job?._id}`)}
                      >
                        {job?.vacancy_title}
                      </TableCell>
                      <TableCell>
                        <Switch
                          defaultChecked={job?.is_live}
                          defaultValue={job?.is_live}
                          color="success"
                          onChange={(e) => handleLive(job?._id, e, "quick")}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {/* Running Table */}
        <Grid item xs={12} md={6} lg={4}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">Running Posts</Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ backgroundColor: "#1e3a8a" }}>
                  <TableCell style={{ color: "white" }}>Title</TableCell>
                  {/* <TableCell style={{ color: "white" }}>Edit</TableCell> */}
                  <TableCell style={{ color: "white" }}>
                    Remove From Running
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {NewJobs?.posts?.length === 0 ? (
                  <TableRow>No data available</TableRow>
                ) : (
                  NewJobs?.posts?.map((job) => (
                    <TableRow key={job?._id}>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/jobs/edit/${job?._id}`)}
                      >
                        {job?.vacancy_title}
                      </TableCell>
                      <TableCell>
                        <Switch
                          defaultChecked={job?.is_live}
                          defaultValue={job?.is_live}
                          color="success"
                          onChange={(e) => handleLive(job?._id, e, "new")}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
