import {
    ADMITCARD_FAILURE,
    ADMITCARD_REQUEST,
    ADMITCARD_SUCCESS,
} from "./admitcard.action";

const initialAdmitCard = {
  isAdmitcardLoading: false,
  isAdmitcardError: null,
  AllAdmitCard: { admitcard: [], nextUrl: null, total: 0 },
};

export const reducer = (state = initialAdmitCard, { type, payload }) => {
  switch (type) {
    case ADMITCARD_REQUEST:
      return {
        ...state,
        isAdmitcardLoading: true,
        isAdmitcardError: null,
      };
    case ADMITCARD_SUCCESS:
      return {
        ...state,
        isAdmitcardLoading: false,
        isAdmitcardError: null,
        AllAdmitCard: {
          ...state.AllAdmitCard,
          nextUrl: payload?.nextUrl,
          admitcard: payload?.admitcard,
          total: payload?.total,
        },
      };
    case ADMITCARD_FAILURE:
      return {
        ...state,
        isAdmitcardLoading: false,
        isAdmitcardError: payload,
      };
    default:
      return state;
  }
};
