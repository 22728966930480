import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../Components/Loader/Loading";
import { DeleteFaq, fetchFaqData, updateFaq } from "../../Redux/faq/faq.action";
import AddFaqModal from "./AddFaqModal";
import EditFaq from "./EditFaq";

const Faq = () => {
  const dispatch = useDispatch();
  const { isLoading, isError, data } = useSelector((state) => state.faqReducer);
  const [open, setOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditClose = () => setOpenEditModal(false);

  useEffect(() => {
    dispatch(fetchFaqData({ page: page, limit: rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    if (isError) {
      toast.error("Error fetching FAQs");
    }
  }, [isError]);

  const handleDelete = () => {
    dispatch(DeleteFaq(deleteId)).then((res) => {
      if (res === "SUCCESS") {
        setIsDeleteModal(false);
      }
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  const handleSwitch = (e, id) => {
    dispatch(updateFaq(id, { is_live: e.target.checked })).then((res) => {
      if (res === "SUCCESS") handleClose();
    });
  };

  return (
    <Box sx={{ marginTop: "5rem" }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          FAQs
        </Typography>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleOpen}
        >
          Add FAQ
        </Button>
      </Box>
      <Box mt={4}>
        {data?.data?.map((faq) => (
          <Box
            key={faq._id}
            mb={3}
            p={2}
            boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
            borderRadius={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "red",
                  fontSize: { lg: "1.1rem", sm: "1.3rem", xs: ".9rem" },
                }}
              >
                Q:~ {faq.question}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "justify",
                  fontSize: { lg: "1rem", sm: "1.1rem", xs: ".8rem" },
                }}
              >
                Ans:~ {faq.answer}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "" }}>
                <Switch
                  defaultChecked={faq?.is_live}
                  color="success"
                  onChange={(e) => handleSwitch(e, faq._id)}
                />

                <IconButton
                  color="primary"
                  onClick={() => {
                    setEditData(faq);
                    setOpenEditModal(true);
                  }}
                >
                  <Edit />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setIsDeleteModal(true);
                    setDeleteId(faq._id);
                  }}
                >
                  <Delete style={{ color: "red" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        flexWrap="wrap"
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        gap={{ lg: "2rem", sm: "1rem", xs: ".5rem" }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            fontSize: { lg: "1.1rem", sm: "1.3rem", xs: ".9rem" },
          }}
        >
          Row Per Page
        </Typography>

        <Select
          value={rowsPerPage}
          onChange={(e) => setRowsPerPage(e.target.value)}
          sx={{
            height: "2rem",
            fontSize: { lg: "1.1rem", sm: "1.3rem", xs: ".9rem" },
          }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
        </Select>
        <Pagination
          count={Math.ceil(data?.total / rowsPerPage)}
          page={page}
          onChange={(e, p) => setPage(p)}
          variant="outlined"
          shape="rounded"
        />
      </Box>

      <AddFaqModal open={open} handleClose={handleClose} />
      <EditFaq
        open={openEditModal}
        handleClose={handleEditClose}
        currentFaq={editData}
      />
      <Modal
        open={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        aria-labelledby="filter-modal-title"
        aria-describedby="filter-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "70%", sm: "50%", md: "25%" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography
            id="filter-modal-title"
            variant="h6"
            component="h2"
            marginBottom={3}
          >
            Are you sure you want to delete this FAQs?
          </Typography>
          <Button
            onClick={() => setIsDeleteModal(false)}
            sx={{ bgcolor: "gray", color: "white", marginRight: "10px" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(deleteId)}
            sx={{ bgcolor: "red", color: "white" }}
          >
            Delete
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default Faq;
