import {
  RESULTS_FAILURE,
  RESULTS_REQUEST,
  RESULTS_SUCCESS,
} from "./result.action";

const initialResults = {
  isResultsLoading: false,
  isResultsError: null,
  AllResults: { results: [], nextUrl: null, total: 0 },
};

export const reducer = (state = initialResults, { type, payload }) => {
  switch (type) {
    case RESULTS_REQUEST:
      return {
        ...state,
        isResultsLoading: true,
        isResultsError: null,
      };
    case RESULTS_SUCCESS:
      return {
        ...state,
        isResultsLoading: false,
        isResultsError: null,
        AllResults: {
          ...state.AllResults,
          nextUrl: payload?.nextUrl,
          results: payload?.results,
          total: payload?.total,
        },
      };
    case RESULTS_FAILURE:
      return {
        ...state,
        isResultsLoading: false,
        isResultsError: payload,
      };
    default:
      return state;
  }
};
