import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import { thunk } from "redux-thunk";
import { reducer as loginReducer } from "./auth/auth.reducer";
import { reducer as faqReducer } from "./faq/faq.reducer";
import { reducer as contactusReducer } from "./contactus/contact.reducer";
import { reducer as JobReducer } from "./jobs/job.reducer";
import { reducer as Admitcard } from "./admitcard/admitcard.reducer";
import { reducer as Results } from "./result/result.reducer";

let rootReducer = combineReducers({
  loginReducer,
  faqReducer,
  contactusReducer,
  JobReducer,
  Admitcard,
  Results,
});
export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
