import { toast } from "react-toastify";
import { axiosInstance } from "../../utils/axiosInstance";

export const CONTACTUS_REQUEST = "CONTACTUS_REQUEST";
export const CONTACTUS_SUCCESS = "CONTACTUS_SUCCESS";
export const CONTACTUS_FAILURE = "CONTACTUS_FAILURE";

export const CONTACTUS_DELETE_REQUEST = "CONTACTUS_DELETE_REQUEST";
export const CONTACTUS_DELETE_SUCCESS = "CONTACTUS_DELETE_SUCCESS";
export const CONTACTUS_DELETE_FAILURE = "CONTACTUS_DELETE_FAILURE";

const constactReaquest = () => ({
  type: CONTACTUS_REQUEST,
});

const contactSuccess = (data) => ({
  type: CONTACTUS_SUCCESS,
  payload: data,
});

const contactFailure = (error) => ({
  type: CONTACTUS_FAILURE,
  payload: error,
});

export const contactUsGetData = (query) => {
  return async (dispatch) => {
    dispatch(constactReaquest());
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axiosInstance.get("/api/v1/contact-us", {
        params: query,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(contactSuccess(response?.data?.data));
      // console.log(response.data);
    } catch (error) {
      dispatch(contactFailure(error.message));
    }
  };
};

const contactDeleteRequest = () => ({
  type: CONTACTUS_DELETE_REQUEST,
});

const contactDeleteFailure = (error) => ({
  type: CONTACTUS_DELETE_FAILURE,
  payload: error,
});

export const contactUsDeleteData = (id) => {
  return async (dispatch) => {
    dispatch(contactDeleteRequest());
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axiosInstance.delete(`/api/v1/contact-us/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.data?.status === 200) {
        toast.success("Contact Us Deleted successfully");
        dispatch(contactUsGetData({ limit: 10 }));
      } else {
        dispatch(contactDeleteFailure(response?.data?.message));
        toast.error("Failed to Delete Contact Us");
      }
    } catch (error) {
      dispatch(contactDeleteFailure(error.message));
      toast.error("Failed to Delete Contact Us");
    }
  };
};

export const updateMessage = (id, data) => {
  return async (dispatch) => {
    dispatch(constactReaquest());
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axiosInstance.put(
        `/api/v1/contact-us/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.status === 200) {
        dispatch(contactUsGetData({ limit: 10 }));
        return "SUCCESS";
      } else {
        dispatch(contactFailure(response?.data.message));
      }
    } catch (error) {
      dispatch(contactFailure(error.message));
    }
  };
};
