import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../Components/Loader/Loading";
import {
  GetAllJobs,
  GetSingleJob,
  updateJob,
} from "../../Redux/jobs/job.action";
import {
  applyMode,
  empty,
  GovTypes,
  initialStates,
  jobType,
  Sectors,
} from "../../utils/constants";
import { formatDateTimeIST, formatDateTimeUTC } from "../../utils/common_func";

const EditJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { jobDetails, isLoading, isError } = useSelector(
    (state) => state.JobReducer
  );
  useEffect(() => {
    dispatch(GetSingleJob(id));
  }, [dispatch, id]);

  const [heading, setHeading] = useState("");
  const [governmentType, setGovernmentType] = useState("");
  const [sector, setSector] = useState("");
  const [postName, setPostName] = useState("");
  const [states, setStates] = useState("");
  const [description, setDescription] = useState("");
  const [advertisement, setAdvertisement] = useState("");
  const [vacancyTitle, setVacancyTitle] = useState("");
  const [institution, setInstitution] = useState("");
  const [loading, setLoading] = useState(false);
  const editor = useRef(null);
  const [applicationFee, setApplicationFee] = useState("");
  const [importantDatesContent, setimportantDatesContent] = useState("");
  const [ageLimits, setAgeLimits] = useState("");
  const [vacancyDetails, setVacancyDetails] = useState("");
  const [qualification, setQualification] = useState("");
  const [instruction, setInstruction] = useState("");
  const [postDate, setPostDate] = useState();
  const [lastDate, setLastDate] = useState();
  const [links, setLinks] = useState([
    { link_name: "", link: "", file: null, type: "URL" },
  ]);
  const [isLive, setIsLive] = useState(false);
  const [isAdmitcard, setIsAdmitcard] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [isQuick, setIsQuick] = useState(false);
  const [applyLinkAvailableFrom, setapplyLinkAvailableFrom] = useState();
  const [ApplyMode, setApplyMode] = useState("");
  const [JobType, setJobType] = useState("");

  const handleGovernmentTypeChange = (e) => {
    setGovernmentType(e.target.value);
    if (e.target.value !== "state") {
      setStates("");
    }
  };

  const handleLinkChange = (index, field, value) => {
    const newLinks = [...links];
    newLinks[index][field] = value;
    setLinks(newLinks);
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];
    const newLinks = [...links];
    newLinks[index].file = file;
    setLinks(newLinks);
  };

  const handleAddLink = () => {
    setLinks([{ link_name: "", link: "", file: null, type: "URL" }, ...links]);
  };

  const handleRemoveLink = (index) => {
    if (
      links.length > 1 ||
      (links.length === 1 &&
        (links[0].link_name || links[0].link || links[0].file))
    ) {
      const newLinks = links.filter((_, i) => i !== index);
      setLinks(newLinks);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      heading,
      gov_type: governmentType,
      sector,
      post_name: postName,
      description,
      advertisement,
      vacancy_title: vacancyTitle,
      institution,
      important_links: links,
      is_live: isLive,
      is_admitcard_avl: isAdmitcard,
      is_results_avl: isResult,
      is_quick_link: isQuick,
      is_new_post: isRunning,
    };

    // Add conditional fields only if they have values
    if (applicationFee) formData.application_fee = applicationFee;
    if (importantDatesContent || postDate || lastDate) {
      formData.important_dates = {
        post_date: formatDateTimeUTC(postDate) || null,
        last_date: formatDateTimeUTC(lastDate) || null,
        content: importantDatesContent || null,
      };
    }
    if (ageLimits) formData.age_limits = ageLimits;
    if (vacancyDetails) formData.vacancy_details = vacancyDetails;
    if (instruction) formData.instruction = instruction;
    if (applyLinkAvailableFrom)
      formData.apply_link_avl_from = formatDateTimeUTC(applyLinkAvailableFrom);
    if (qualification) formData.qualification = qualification;

    // Add states only if governmentType is 'state' and states have value
    if (governmentType === "state" && states) {
      formData.states = states;
    }

    dispatch(updateJob(id, formData))
      .then((res) => {
        if (res === "SUCCESS") {
          toast.success("Data Updated successfully!");
          setLoading(false);
          dispatch(GetAllJobs());
        } else {
          toast.error(res);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error);
      });
  };

  useEffect(() => {
    if (!isLoading && isError === null && jobDetails) {
      setHeading(jobDetails?.heading);
      setGovernmentType(jobDetails?.gov_type);
      setSector(jobDetails?.sector);
      setPostName(jobDetails?.post_name);
      setStates(jobDetails?.states);
      setDescription(jobDetails?.description);
      setAdvertisement(jobDetails?.advertisement);
      setVacancyTitle(jobDetails?.vacancy_title);
      setInstitution(jobDetails?.institution);
      setApplicationFee(jobDetails?.application_fee);
      setimportantDatesContent(jobDetails?.important_dates?.content);
      setAgeLimits(jobDetails?.age_limits);
      setVacancyDetails(jobDetails?.vacancy_details);
      setInstruction(jobDetails?.instruction);
      setLinks(jobDetails?.important_links);
      setIsLive(jobDetails?.is_live);
      setIsAdmitcard(jobDetails?.is_admitcard_avl);
      setIsResult(jobDetails?.is_results_avl);
      setIsQuick(jobDetails?.is_quick_link);
      setIsRunning(jobDetails?.is_new_post);
      setPostDate(formatDateTimeIST(jobDetails?.important_dates?.post_date));
      setLastDate(formatDateTimeIST(jobDetails?.important_dates?.last_date));
      setQualification(jobDetails?.qualification);
      setapplyLinkAvailableFrom(
        formatDateTimeIST(jobDetails?.apply_link_avl_from)
      );
      setApplyMode(jobDetails?.apply_mode);
      setJobType(jobDetails?.job_type);
    }
  }, [id, isLoading, isError, jobDetails]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : isError ? (
        <h1>{"Something went wrong"}</h1>
      ) : (
        <div>
          {/* --------------------form-------------------- */}

          <Typography
            marginTop={"50px"}
            variant="h4"
            sx={{ textAlign: "center" }}
          >
            EDIT POST
          </Typography>
          <Box
            component="form"
            onSubmit={handleSave}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mb: "2rem",
              padding: "16px",
            }}
          >
            {/*----------------------- Loading overlay------------ */}
            {loading && (
              <Box
                sx={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 9999,
                }}
              >
                <CircularProgress size={50} />
              </Box>
            )}

            {/* Heading and title post name */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormLabel>Heading*</FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Heading"
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                  disabled={loading}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabel>Vacancy Title*</FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Vacancy Title"
                  value={vacancyTitle}
                  onChange={(e) => setVacancyTitle(e.target.value)}
                  disabled={loading}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabel>Post Name*</FormLabel>
                <TextField
                  fullWidth
                  placeholder="Post Name"
                  value={postName}
                  onChange={(e) => setPostName(e.target.value)}
                  disabled={loading}
                  required
                />
              </Grid>
            </Grid>

            {/* Gov Type, Sector, Advetisement */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormLabel>Government Type*</FormLabel>
                <Select
                  fullWidth
                  value={governmentType}
                  onChange={handleGovernmentTypeChange}
                  disabled={loading}
                  required
                >
                  {GovTypes?.map((govType) => (
                    <MenuItem key={govType} value={govType}>
                      {govType.toLocaleUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabel>Sector*</FormLabel>
                <Select
                  fullWidth
                  value={sector}
                  onChange={(e) => setSector(e.target.value)}
                  disabled={loading}
                  required
                >
                  {Sectors?.map((sector) => (
                    <MenuItem key={sector} value={sector}>
                      {sector.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabel>Advertisement*</FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Advertisement"
                  value={advertisement}
                  onChange={(e) => setAdvertisement(e.target.value)}
                  disabled={loading}
                  required
                />
              </Grid>
            </Grid>

            {governmentType === "state" && (
              <Box mb={2}>
                <FormLabel>State*</FormLabel>
                <Select
                  fullWidth
                  value={states}
                  placeholder="Select State"
                  onChange={(e) => setStates(e.target.value)}
                  disabled={loading}
                >
                  {initialStates?.map((state) => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            {/*- Vacancy Title, Institution */}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormLabel>Institution*</FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  placeholder="Institution"
                  value={institution}
                  onChange={(e) => setInstitution(e.target.value)}
                  disabled={loading}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabel>Post Date*</FormLabel>
                <TextField
                  fullWidth
                  type="datetime-local"
                  placeholder="Post Date"
                  value={postDate}
                  onChange={(e) => setPostDate(e.target.value)}
                  disabled={loading}
                  // required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabel>Last Date*</FormLabel>
                <TextField
                  fullWidth
                  type="datetime-local"
                  placeholder="Last Date"
                  value={lastDate}
                  onChange={(e) => setLastDate(e.target.value)}
                  disabled={loading}
                  // required
                />
              </Grid>
            </Grid>

            {/* Apply Link Available From */}
            <Box mt={2}>
              <Grid container spacing={2} alignItems="center" mb={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormLabel>Apply Link Avl. From (optional)</FormLabel>
                  <TextField
                    fullWidth
                    type="datetime-local"
                    placeholder="Post Date"
                    value={applyLinkAvailableFrom}
                    onChange={(e) => setapplyLinkAvailableFrom(e.target.value)}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormLabel>Apply Mode</FormLabel>
                  <Select
                    fullWidth
                    value={ApplyMode}
                    onChange={(e) => setApplyMode(e.target.value)}
                    disabled={loading}
                    // required
                  >
                    {applyMode?.map((mode) => (
                      <MenuItem key={mode} value={mode}>
                        {mode.toLocaleUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormLabel>Job Type</FormLabel>
                  <Select
                    fullWidth
                    value={JobType}
                    onChange={(e) => setJobType(e.target.value)}
                    disabled={loading}
                    // required
                  >
                    {jobType?.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type.toLocaleUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>

            {/* Description */}
            <Box mb={2}>
              <FormLabel>Description*</FormLabel>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={loading}
                required
              />
            </Box>

            {/* Important Dates Content */}
            <Box mb={2}>
              <FormLabel>Important Dates Content (optional)</FormLabel>
              <JoditEditor
                ref={editor}
                value={importantDatesContent}
                onBlur={(newContent) => setimportantDatesContent(newContent)}
                disabled={loading}
                // required
              />
            </Box>

            {/* Application Fee */}
            <Box mb={2}>
              <FormLabel>Application Fee (optional)</FormLabel>
              <JoditEditor
                ref={editor}
                value={applicationFee}
                onBlur={(newContent) => setApplicationFee(newContent)}
                disabled={loading}
                // required
              />
            </Box>

            {/* Age Limits */}
            <Box mb={2}>
              <FormLabel>Age Limits (optional)</FormLabel>
              <JoditEditor
                ref={editor}
                value={ageLimits}
                onBlur={(newContent) => setAgeLimits(newContent)}
                disabled={loading}
                // required
              />
            </Box>

            {/* Vacancy Details */}
            <Box mb={2}>
              <FormLabel>Qualification (optional)</FormLabel>
              <JoditEditor
                ref={editor}
                value={qualification}
                onBlur={(newContent) => setQualification(newContent)}
                disabled={loading}
                // required
              />
            </Box>

            {/* Vacancy Details */}
            <Box mb={2}>
              <FormLabel>Vacancy Details (optional)</FormLabel>
              <JoditEditor
                ref={editor}
                value={vacancyDetails}
                onBlur={(newContent) => setVacancyDetails(newContent)}
                disabled={loading}
                // required
              />
            </Box>

            {/* Instruction */}
            <Box mb={2}>
              <FormLabel>Instruction (optional)</FormLabel>
              <JoditEditor
                ref={editor}
                value={instruction}
                onBlur={(newContent) => setInstruction(newContent)}
                disabled={loading}
                // required
              />
            </Box>

            {/* Important Links */}
            <Box mt={2}>
              <FormLabel>Important Links</FormLabel>
              {links?.map((link, index) => (
                <Grid
                  container
                  spacing={2}
                  key={index}
                  alignItems="center"
                  mb={2}
                >
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      placeholder="Link Name"
                      value={link.link_name}
                      onChange={(e) =>
                        handleLinkChange(index, "link_name", e.target.value)
                      }
                      disabled={loading}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {link.type === "URL" ? (
                      <TextField
                        placeholder="URL"
                        value={link.link}
                        onChange={(e) =>
                          handleLinkChange(index, "link", e.target.value)
                        }
                        disabled={loading}
                        fullWidth
                      />
                    ) : (
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(index, e)}
                        disabled={loading}
                        style={{ width: "100%" }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <Select
                      value={link.type}
                      onChange={(e) =>
                        handleLinkChange(index, "type", e.target.value)
                      }
                      disabled={loading}
                      fullWidth
                    >
                      <MenuItem value="URL">URL</MenuItem>
                      <MenuItem value="File">File</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <IconButton
                      onClick={() => handleRemoveLink(index)}
                      disabled={loading}
                      color="error"
                    >
                      <MdDeleteForever />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Button
                onClick={handleAddLink}
                disabled={loading}
                variant="contained"
                color="primary"
              >
                Add Link
              </Button>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={{ xs: 1, sm: 1 }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography fontSize={{ xs: "12px", sm: "14px" }}>
                  Live
                </Typography>
                <Switch
                  checked={isLive}
                  color="success"
                  onChange={(e) => setIsLive(e.target.checked)}
                />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography fontSize={{ xs: "12px", sm: "14px" }}>
                  AdmitCard
                </Typography>
                <Switch
                  checked={isAdmitcard}
                  color="success"
                  onChange={(e) => setIsAdmitcard(e.target.checked)}
                />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography fontSize={{ xs: "12px", sm: "14px" }}>
                  Results
                </Typography>
                <Switch
                  checked={isResult}
                  color="success"
                  onChange={(e) => setIsResult(e.target.checked)}
                />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography fontSize={{ xs: "12px", sm: "14px" }}>
                  Running Post
                </Typography>
                <Switch
                  checked={isRunning}
                  color="success"
                  onChange={(e) => setIsRunning(e.target.checked)}
                />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography fontSize={{ xs: "12px", sm: "14px" }}>
                  Quick Post
                </Typography>
                <Switch
                  checked={isQuick}
                  color="success"
                  onChange={(e) => setIsQuick(e.target.checked)}
                />
              </Box>
            </Box>

            <Stack direction="row" justifyContent="end" spacing={2} mt={4}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Save
              </Button>
              <Button
                onClick={() => navigate("/jobs")}
                variant="outlined"
                color="secondary"
                disabled={loading}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default EditJob;
