import { axiosInstance } from "../../utils/axiosInstance";

export const ADMITCARD_REQUEST = "ADMITCARD_REQUEST";
export const ADMITCARD_SUCCESS = "ADMITCARD_SUCCESS";
export const ADMITCARD_FAILURE = "ADMITCARD_FAILURE";

export const GetAllAdmitCards = (query) => {
  return async (dispatch) => {
    dispatch({ type: ADMITCARD_REQUEST });
    const token = localStorage.getItem("accessToken");
    try {
      const response = await axiosInstance.get("/admin/api/v1/admitcard", {
        params: query,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.status === 201) {
        dispatch({ type: ADMITCARD_SUCCESS, payload: response?.data?.data });
      } else {
        dispatch({ type: ADMITCARD_FAILURE, payload: response?.data?.message });
      }
    } catch (error) {
      dispatch({ type: ADMITCARD_FAILURE, payload: error?.message });
    }
  };
};
