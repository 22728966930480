import { Box, Button, Modal, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateFaq } from "../../Redux/faq/faq.action"; // Make sure to include these actions

const EditFaq = ({ open, handleClose, currentFaq }) => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    if (currentFaq) {
      setQuestion(currentFaq.question);
      setAnswer(currentFaq.answer);
    }
  }, [currentFaq]);

  const handleSubmit = () => {
    if (currentFaq) {
      dispatch(updateFaq(currentFaq._id, { question, answer })).then((res) => {
        if (res === "SUCCESS") handleClose();
      });
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "75%", md: "50%", lg: "40%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 1,
        }}
      >
        <TextField
          label="Question"
          fullWidth
          margin="normal"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <TextField
          label="Answer"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} sx={{ ml: 2 }}>
            Update
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditFaq;
