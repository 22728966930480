import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoFilter } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../Components/Loader/Loading";
import { GetAllAdmitCards } from "../../Redux/admitcard/admitcard.action";
import { deleteJob, updateJob } from "../../Redux/jobs/job.action";
import { formatDateTimeIST } from "../../utils/common_func";
import { GovTypes, initialStates, Sectors } from "../../utils/constants";

const Admitcard = () => {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTxt, setSearchTxt] = useState("");
  const [filter, setFilter] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { AllAdmitCard, isAdmitcardLoading, isAdmitcardError } = useSelector(
    (state) => state.Admitcard
  );

  useEffect(() => {
    dispatch(GetAllAdmitCards({ page: page + 1, limit: rowsPerPage }));
  }, [dispatch, page, rowsPerPage]);

  const handleFilterModalOpen = () => {
    setFilter({});
    setIsFilterModalOpen(true);
  };
  const handleFilterModalClose = () => setIsFilterModalOpen(false);

  const handleLive = (id, e) => {
    dispatch(updateJob(id, { is_live: e.target.checked })).then((res) => {
      if (res === "SUCCESS") {
        toast.success("Admit Card updated successfully!");
        dispatch(GetAllAdmitCards());
      } else {
        toast.error("Something went wrong. Please try again later");
      }
    });
  };

  const handleEdit = (id) => {
    navigate(`/jobs/edit/${id}`);
  };

  const handleDelete = () => {
    dispatch(deleteJob(deleteId)).then((res) => {
      if (res === "SUCCESS") {
        toast.success("Admitcard Deleted successfully!");
        dispatch(GetAllAdmitCards());
      } else {
        toast.error("Something went wrong. Please try again later");
      }
      setIsDeleteModal(false);
      setDeleteId(null);
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(() => newPage);
    // console.log(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const search = () => {
    dispatch(GetAllAdmitCards({ search: searchTxt, limit: 100 }));
  };

  const handleFilter = (e) => {
    e.preventDefault();
    dispatch(GetAllAdmitCards({ ...filter, limit: 100 }));
    setIsFilterModalOpen(false);
  };

  return (
    <>
      {isAdmitcardLoading ? (
        <Loading />
      ) : isAdmitcardError !== null ? (
        <Typography variant="h4" gutterBottom>
          Something went wrong. Please try again later
        </Typography>
      ) : (
        <Box sx={{ mt: "3rem", mx: { xs: "1rem", sm: "2rem", md: "3rem" } }}>
          <Typography variant="h4" gutterBottom>
            Admit Cards
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "stretch", sm: "center" }}
            sx={{ mb: 2, gap: { xs: 2, sm: 0 } }}
          >
            <Box></Box>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              gap="10px"
            >
              <Input
                placeholder="Search by title"
                sx={{ borderRadius: 0, mb: { xs: 1, sm: 0 } }}
                onChange={(e) => setSearchTxt(e.target.value)}
              />
              <Button
                variant="contained"
                sx={{ borderRadius: 0, mb: { xs: 1, sm: 0 } }}
                onClick={search}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                sx={{ borderRadius: 0, display: "flex", alignItems: "center" }}
                onClick={handleFilterModalOpen}
              >
                <IoFilter />
                <Typography>Filter</Typography>
              </Button>
            </Box>
          </Box>

          <TableContainer
            sx={{
              mt: 2,
              border: "1px solid gray",
              overflowX: "auto",
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#1976d2",
                  }}
                >
                  <TableCell sx={{ color: "white" }}>Title</TableCell>
                  <TableCell sx={{ color: "white" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AllAdmitCard?.admitcard?.length === 0 ? (
                  <TableRow sx={{ textAlign: "center" }}>
                    No data available
                  </TableRow>
                ) : (
                  AllAdmitCard?.admitcard?.map((job, i) => (
                    <TableRow
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: `${i % 2 === 0 ? "#c9d4e7" : "white"}`,
                      }}
                      key={job?._id}
                    >
                      <TableCell
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleEdit(job._id)}
                      >
                        <Typography>{job?.vacancy_title}</Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            marginTop: "5px",
                            color: "gray",
                          }}
                        >
                          Created: {formatDateTimeIST(job?.createdAt)} By:{" "}
                          {job?.created_by?.name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ display: "flex", gap: "10px" }}>
                        <IconButton
                          color="error"
                          onClick={() => {
                            setIsDeleteModal(true);
                            setDeleteId(job?._id);
                          }}
                        >
                          <MdDelete />
                        </IconButton>
                        <Switch
                          defaultChecked={job?.is_live}
                          defaultValue={job?.is_live}
                          color="success"
                          onChange={(e) => handleLive(job?._id, e)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 100]}
                // component="div"
                count={AllAdmitCard?.total || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>

          {/* <Add isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} mb={"rem"} /> */}

          <Modal
            open={isFilterModalOpen}
            onClose={handleFilterModalClose}
            aria-labelledby="filter-modal-title"
            aria-describedby="filter-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "90%", sm: "70%", md: "50%" },
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Typography id="filter-modal-title" variant="h6" component="h2">
                Filter Jobs
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="filter-gov-type-label">Sector</InputLabel>
                    <Select
                      labelId="filter-gov-type-label"
                      label="Government Type"
                      onChange={(e) =>
                        setFilter({ ...filter, sector: e.target.value })
                      }
                    >
                      {Sectors?.map((sector) => (
                        <MenuItem key={sector} value={sector}>
                          {sector}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="filter-gov-type-label">
                      Government Type
                    </InputLabel>
                    <Select
                      labelId="filter-gov-type-label"
                      label="Government Type"
                      onChange={(e) =>
                        setFilter({ ...filter, gov_type: e.target.value })
                      }
                    >
                      {GovTypes?.map((govType) => (
                        <MenuItem key={govType} value={govType}>
                          {govType}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {filter?.gov_type === "state" && (
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="filter-state-label">State</InputLabel>
                      <Select
                        labelId="filter-state-label"
                        label="State"
                        onChange={(e) =>
                          setFilter({ ...filter, states: e.target.value })
                        }
                      >
                        {initialStates?.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="filter-gov-type-label">Live</InputLabel>
                    <Select
                      labelId="filter-gov-type-label"
                      label="Government Type"
                      onChange={(e) =>
                        setFilter({ ...filter, is_live: e.target.value })
                      }
                    >
                      <MenuItem value={"true"}>True</MenuItem>
                      <MenuItem value={"false"}>false</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="filter-gov-type-label">Deleted</InputLabel>
                    <Select
                      labelId="filter-gov-type-label"
                      label="Government Type"
                      onChange={(e) =>
                        setFilter({ ...filter, is_deleted: e.target.value })
                      }
                    >
                      <MenuItem value={"true"}>True</MenuItem>
                      <MenuItem value={"false"}>false</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFilter}
                >
                  Apply Filters
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={isDeleteModal}
            onClose={() => setIsDeleteModal(false)}
            aria-labelledby="filter-modal-title"
            aria-describedby="filter-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: { xs: "70%", sm: "50%", md: "25%" },
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                textAlign: "center",
              }}
            >
              <Typography
                id="filter-modal-title"
                variant="h6"
                component="h2"
                marginBottom={3}
              >
                Are you sure you want to delete this Post?
              </Typography>
              <Button
                onClick={() => setIsDeleteModal(false)}
                sx={{ bgcolor: "gray", color: "white", marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDelete(deleteId)}
                sx={{ bgcolor: "red", color: "white" }}
              >
                Delete
              </Button>
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default Admitcard;
