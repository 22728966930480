export const initialStates = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
  "Ladakh",
  "Jammu and Kashmir",
];
export const GovTypes = ["central", "state", "private", "other"];
export const Sectors = [
  "engineering",
  "medical",
  "railway",
  "ssc",
  "bank",
  "upsc",
  "psc",
  "defence",
  "other",
  "Education_and_Teaching",
  "Law_and_Justice",
  "Police_and_Law Enforcement",
  "Agriculture_and_Rural Development",
  "Science_and_Research",
  "Social Welfare_and_Public Distribution",
  "Environment_and_Wildlife",
];
export const applyMode = ["online", "offline", "email", "walkin", "other"];
export const jobType = ["regular", "contractual", "internship", "other"];
export const initialImpDate = `<ul><li><strong>Application Start Date:&nbsp;07/10/2024</strong></li><li><strong>Application Last Date:&nbsp;<span style="color: rgb(255, 0, 0);">04/11/2024</span></strong></li></ul>`;
export const initialAgeCont = `<ul><li>Minimum: <strong>21 Years</strong></li><li>Maximum: <strong>30 Years</strong></li><li><strong>Age Relaxation will be applicable as per gov rule.</strong></li></ul>`;
export const initialFeeCont = `<ul><li>General / OBC / EWS: <strong>Rs. 200 /- </strong></li><li><span style="color: rgb(0, 0, 0);">SC / ST / PwD / Female: <strong>Rs. 0 /- </strong>&nbsp;</span></li></ul>`;
export const initialInstructConst = `<p><strong>How to Apply &amp; Instructions:</strong></p>`;
export const initaialImpLinks = [
  {
    link_name: "Official Notification",
    link: "",
    file: null,
    type: "URL",
  },
  { link_name: "Official Website", link: "", file: null, type: "URL" },
  {
    link_name: "Join Telegram Channel",
    link: "https://t.me/sarkarijobsamachardotcom",
    file: null,
    type: "URL",
  },
  {
    link_name: "Join WhatsApp Channel",
    link: "https://whatsapp.com/channel/0029VakgPzcB4hdYEeVouX2f",
    file: null,
    type: "URL",
  },
];
export const userRole = ["admin", "financial", "operational"];
export const empty = "<p><br></p>";
